import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'

import 'react-app-polyfill/stable'
import 'core-js'
import * as serviceWorker from './serviceWorker'
import 'antd/dist/antd.css'
import 'leaflet/dist/leaflet.css'
import './index.css'
import './index.scss'

import { icons } from './assets/icons'

import { Provider } from 'react-redux'
import { store } from './redux/store'

React.icons = icons

var sessionStorage_transfer = function (event) {
  if (!event) {
    event = window.event
  } // ie suq
  if (!event.newValue) return // do nothing if no value to work with
  if (event.key == 'getSessionStorage') {
    // another tab asked for the sessionStorage -> send it
    localStorage.setItem('sessionStorage', JSON.stringify(sessionStorage))
    // the other tab should now have it, so we're done with it.
    localStorage.removeItem('sessionStorage') // <- could do short timeout as well.
  } else if (event.key == 'sessionStorage' && !sessionStorage.length) {
    // another tab sent data <- get it
    var data = JSON.parse(event.newValue)
    for (var key in data) {
      sessionStorage.setItem(key, data[key])
    }
  }
}

// listen for changes to localStorage
if (window.addEventListener) {
  window.addEventListener('storage', sessionStorage_transfer, false)
} else {
  window.attachEvent('onstorage', sessionStorage_transfer)
}

// Ask other tabs for session storage (this is ONLY to trigger event)
if (!sessionStorage.length) {
  localStorage.setItem('getSessionStorage', 'foobar')
  localStorage.removeItem('getSessionStorage', 'foobar')
}

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('root'),
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister()
