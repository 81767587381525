import {
  CHANGE_AMOUNT_LOAD_APP,
  CLEAR_LOAD_APPLICATIONS,
  GET_BLACK_LIST_PERSON,
  GET_DATA_FROM_GNS,
  GET_DATA_SCORING,
  GET_LIST_LOAD_APPLICATIONS,
  GET_MORE_DATA_LOAD_APPLICATIOIN,
  GET_PDF_REPORT,
  GET_SOC_FONT,
  GET_TAXPAYER_BY_PIN,
  GET_TOTAL_COUNT_INCREASE_LIMIT_LOAD_APP,
  GET_TOTAL_COUNT_LOAD_APP,
  TRIGGER_LOG_LOAD_APP,
} from '../constants/constants'

const initialState = {
  loanUser: null,
  loanUserFiles: null,
  users: {},
  moreData: {},
  scoring: {},
  gns: {},
  socFont: {},
  taxpayer: {},
  pdf: '',
  creditInfo: {
    isContent: false,
    content: '',
  },
  blackList: [],
  totalCount: 0,
  totalCountIncreaseLimit: 0,
  images: [],
  videos: [],
  processed: false,
  fuelLoanAppInfo: {
    hasFuelLoanApp: false,
    fuelLoanAppId: null,
  },
  creditLineStatus: {},
}

const getLoadApplications = (state = initialState, actions) => {
  switch (actions.type) {
    case GET_LIST_LOAD_APPLICATIONS:
      return {
        ...state,
        users: actions.payload,
      }

    case GET_MORE_DATA_LOAD_APPLICATIOIN:
      return {
        ...state,
        moreData: actions.payload,
      }

    case GET_DATA_SCORING:
      return {
        ...state,
        scoring: actions.payload,
      }

    case 'RES_IMAGE_LOAD_APP':
      return {
        ...state,
        images: [...state.images, actions.payload],
      }
    case 'RES_VIDEO_LOAD_APP':
      return {
        ...state,
        videos: actions.payload,
      }

    case GET_PDF_REPORT:
      return {
        ...state,
        pdf: actions.payload,
      }
    case GET_SOC_FONT:
      return {
        ...state,
        socFont: actions.payload,
      }
    case GET_TAXPAYER_BY_PIN:
      return {
        ...state,
        taxpayer: actions.payload,
      }
    case GET_DATA_FROM_GNS:
      return {
        ...state,
        gns: actions.payload,
      }

    case GET_BLACK_LIST_PERSON:
      return {
        ...state,
        blackList: actions.payload,
      }
    case GET_TOTAL_COUNT_LOAD_APP:
      return {
        ...state,
        totalCount: actions.payload,
      }
    case GET_TOTAL_COUNT_INCREASE_LIMIT_LOAD_APP:
      return {
        ...state,
        totalCountIncreaseLimit: actions.payload,
      }
    case TRIGGER_LOG_LOAD_APP:
      return {
        ...state,
        processed: actions.payload === 200,
      }

    case CHANGE_AMOUNT_LOAD_APP:
      return {
        ...state,
        moreData: { ...state.moreData, requestingAmount: actions.payload },
      }
    case 'HAS_FUEL_LOAN_APP':
      return {
        ...state,
        fuelLoanAppInfo: actions.payload,
      }

    case 'GET_LOAN_USER':
      return {
        ...state,
        loanUser: actions.payload,
      }
    case 'LOAN_USER_FILES':
      return {
        ...state,
        loanUserFiles: actions.payload,
      }

    case 'GET_STATUS_CREDIT_LINE':
      return {
        ...state,
        creditLineStatus: actions.payload,
      }
    case CLEAR_LOAD_APPLICATIONS:
      return {
        ...state,
        users: {},
        moreData: {},
        scoring: {},
        gns: {},
        socFont: {},
        taxpayer: {},
        creditLineStatus: {},
        pdf: '',
        creditInfo: {
          isContent: false,
          content: '',
        },
        blackList: [],
        images: [],
        videos: [],
        processed: false,
      }
    default:
      return state
  }
}
export default getLoadApplications
