// ! Identification
export const GET_IDENTIFICATION_LIST_USER_MY = 'GET_IDENTIFICATION_LIST_USER_MY'
export const GET_IDENTIFICATION_LIST_USER = 'GET_IDENTIFICATION_LIST_USER'
export const GET_DATA_USER_BY_ID = 'GET_DATA_USER_BY_ID'
export const GET_LIST_LOAD_APPLICATIONS = 'GET_LIST_LOAD_APPLICATIONS'
export const GET_DATA_SCORING = 'GET_DATA_SCORING'
export const GET_MORE_DATA_LOAD_APPLICATIOIN = 'GET_MORE_DATA_LOAD_APPLICATIOIN'
export const GET_PASSPORT_DATA_FOR_IDENTIFICATION = 'GET_PASSPORT_DATA_FOR_IDENTIFICATION'
export const CLEAR_LOAD_APPLICATIONS = 'CLEAR_LOAD_APPLICATIONS'

// ! partner identification
export const GET_IDENTIFICATION_LIST_PARTNER = 'GET_IDENTIFICATION_LIST_PARTNER'
export const GET_IDENTIFICATION_LIST_PARTNER_MY = 'GET_IDENTIFICATION_LIST_PARTNER_MY'
export const GET_IDENTIFICATION_LIST_PARTNER_ADMIN = 'GET_IDENTIFICATION_LIST_PARTNER_ADMIN'
export const GET_TOTAL_COUNT_PENDING_PARTNER = 'GET_TOTAL_COUNT_PENDING_PARTNER'
export const FILTER_DATA_IDENTIFICATE_PARTNER = 'FILTER_DATA_IDENTIFICATE_PARTNER'
export const GET_DATA_PARTNER_BY_ID = 'GET_DATA_PARTNER_BY_ID'

// ! fuel loan applications
export const TRIGGER_LOG_FUEL_LOAN_APP = 'TRIGGER_LOG_FUEL_LOAN_APP'
export const GET_DATA_FUEL_SCORING = 'GET_DATA_FUEL_SCORING'
export const GET_LIST_FUEL_LOAN_APPLICATIONS = 'GET_LIST_FUEL_LOAN_APPLICATIONS'
export const GET_MORE_DATA__FUEL_LOAN_APPLICATION = 'GET_MORE_DATA__FUEL_LOAN_APPLICATION'
export const GET_TOTAL_COUNT_FUEL_LOAN_APP = 'GET_TOTAL_COUNT_FUEL_LOAN_APP'
export const FILTER_DATA_FUEL_LOAN_APP = 'FILTER_DATA_FUEL_LOAN_APP'
export const CLEAR_FUEL_LOAN_APPLICATIONS = 'CLEAR_FUEL_LOAN_APPLICATIONS'
export const GET_FUEL_APP_PERSONAL_DATA = 'GET_FUEL_APP_PERSONAL_DATA'
export const CHANGE_AMOUNT_FUEL_LOAN_APP = 'CHANGE_AMOUNT_FUEL_LOAN_APP'
export const GET_NEED_TO_EDIT_COMMENT = 'GET_NEED_TO_EDIT_COMMENT'
export const GET_FUEL_PDF_REPORT = 'GET_FUEL_PDF_REPORT'
export const GET_FUEL_TAXPAYER_BY_PIN = 'GET_FUEL_TAXPAYER_BY_PIN'
export const GET_FUEL_DATA_FROM_GNS = 'GET_FUEL_DATA_FROM_GNS'
export const GET_FUEL_SOC_FONT = 'GET_FUEL_SOC_FONT'

// ! Partner Notifications
export const GET_PARTNER_NOTIFICATIONS = 'GET_PARTNER_NOTIFICATIONS'
export const GET_PARTNER_NOTIFICATION_BY_ID = 'GET_PARTNER_NOTIFICATION_BY_ID'
export const SAVE_PAGINATION_PARTNER_NOTIFICATIONS = 'SAVE_PAGINATION_PARTNER_NOTIFICATIONS'

export const GET_ALL_TAGS = 'GET_ALL_TAGS'
export const GET_ALL_CATEGORY = 'GET_ALL_CATEGORY'

export const GET_LIST_PARTNERS = 'GET_LIST_PARTNERS'
export const GET_PARTNER_BY_ID = 'GET_PARTNER_BY_ID'

export const GET_LIST_POSTACCOUNTS = 'GET_LIST_POSTACCOUNTS'
export const ADD_POSTACCOUNTS = 'ADD_POSTACCOUNTS'

export const GET_DATA_DETAIL_BRANCH = 'GET_DATA_DETAIL_BRANCH'

export const GET_DATA_FOR_CONNECTION_VIDEO = 'GET_DATA_FOR_CONNECTION_VIDEO'
export const GET_LIST_MALL = 'GET_LIST_MALL'
export const GET_DETAIL_DATA_MALL = 'GET_DETAIL_DATA_MALL'
export const CLEAR_DATA_DETAIL_MALL = 'CLEAR_DATA_DETAIL_MALL'
export const GIT_LIST_STAFF = 'GIT_LIST_STAFF'
export const GET_LIST_ROLES = 'GET_LIST_ROLES'
export const GET_DETAIL_STAFF = 'GET_DETAIL_STAFF'

export const GET_DATA_CREDIT_INFO = 'GET_DATA_CREDIT_INFO'
export const NO_CONTENT_CREDIT_INFO = 'NO_CONTENT_CREDIT_INFO'

export const LOADING_PASSWORD_DATA = 'LOADING_PASSWORD_DATA'
export const LOADING_PASSWORD_DATA_FINISHED = 'LOADING_PASSWORD_DATA_FINISHED'

export const GET_PDF_REPORT = 'GET_PDF_REPORT'
export const CLEAR_STORE_PARTNER_DATA = 'CLEAR_STORE_PARTNER_DATA'
export const CLEAR_DATA_FOR_CONNECTION_VIDEO = 'CLEAR_DATA_FOR_CONNECTION_VIDEO'

export const GET_LIST_ACCOUNTS_FOR_PARTNER = 'GET_LIST_ACCOUNTS_FOR_PARTNER'

export const GET_DATA_FROM_GNS = 'GET_DATA_FROM_GNS'
export const GET_TAXPAYER_BY_PIN = 'GET_TAXPAYER_BY_PIN'
export const GET_BLACK_LIST_PERSON = 'GET_BLACK_LIST_PERSON'

export const GET_SOC_FONT = 'GET_SOC_FONT'

export const GET_TOTAL_COUNT_PENDING = 'GET_TOTAL_COUNT_PENDING'
export const GET_TOTAL_COUNT_PHOTO = 'GET_TOTAL_COUNT_PHOTO'

export const GET_TOTAL_COUNT_LOAD_APP = 'GET_TOTAL_COUNT_LOAD_APP'
export const GET_TOTAL_COUNT_INCREASE_LIMIT_LOAD_APP = 'GET_TOTAL_COUNT_INCREASE_LIMIT_LOAD_APP'

export const IS_CHECKED_IDENDIFICATE = 'IS_CHECKED_IDENDIFICATE'
export const GET_INQUIRIES = 'GET_INQUIRIES'
export const TRIGGER_LOG_LOAD_APP = 'TRIGGER_LOG_LOAD_APP'
export const CHANGE_AMOUNT_LOAD_APP = 'CHANGE_AMOUNT_LOAD_APP'

export const FILTER_DATA_IDENTIFICATE = 'FILTER_DATA_IDENTIFICATE'
export const FILTER_DATA_CATEGORY = 'FILTER_DATA_CATEGORY'
export const FILTER_DATA_LOAD_APP = 'FILTER_DATA_LOAD_APP'
export const GET_ALL_TAGS_FOR_CREATE = 'GET_ALL_TAGS_FOR_CREATE'

export const GET_LIST_PROMOTIONS = 'GET_LIST_PROMOTIONS'

export const GET_MORE_INFO_PROMOTION = 'GET_MORE_INFO_PROMOTION'
export const FILTER_DATA_PROMOTION = 'FILTER_DATA_PROMOTION'

export const GET_ALL_COMMENTS = 'GET_ALL_COMMENTS'

export const GET_IDENTIFICATION_LIST_USER_ADMIN = 'GET_IDENTIFICATION_LIST_USER_ADMIN'
export const GET_LIST_BONUSES = 'GET_LIST_BONUSES'

export const GET_LIST_BONUSES_SYSTEMS = 'GET_LIST_BONUSES_SYSTEMS'
export const CREATE_BONUSES_SYSTEMS = 'CREATE_BONUSES_SYSTEMS'
export const GET_LIST_USERS_OIDC = 'GET_LIST_USERS_OIDC'
export const GET_LIST_ACCOUNTS_USER_OIDC = 'GET_LIST_ACCOUNTS_USER_OIDC'
export const FILTER_DATA_BONUSES = 'FILTER_DATA_BONUSES'
export const CHANGE_PAGE_BUTTON = 'GANGE_PAGE_BUTTON'
export const GET_LIST_USER_FOR_REPORTS = 'GET_LIST_USER_FOR_REPORTS'
export const GET_CATEGORY_BY_ID = 'GET_CATEGORY_BY_ID'
export const GET_TAG_BY_ID = 'GET_TAG_BY_ID'
export const CLEAR_TAGS_AND_CATEGORY = 'CLEAR_TAGS_AND_CATEGORY'
export const FILTER_DATA_TAGS = 'FILTER_DATA_TAGS'

export const GET_NEWS = 'GET_NEWS'
export const GET_NEWS_BY_ID = 'GET_NEWS_BY_ID'
export const FILTER_DATA_NEWS = 'FILTER_DATA_NEWS'

export const GET_FEEDBACK = 'GET_FEEDBACK'
export const GET_FEEDBACK_BY_ID = 'GET_FEEDBACK_BY_ID'

export const CLEAR_TAGS_AND_SETTLEMENTS = 'CLEAR_TAGS_AND_SETTLEMENTS'
export const GET_ALL_SETTLEMENTS = 'GET_ALL_SETTLEMENTS'
export const GET_SETTLEMENTS_BY_ID = 'GET_SETTLEMENTS_BY_ID'
export const FILTER_DATA_SETTLEMENT = 'FILTER_DATA_SETTLEMENT'

export const PARTNER_TAB = 'PARTNER_TAB'
export const GET_PARTNER_DISCOUNTS = 'GET_PARTNER_DISCOUNTS'
export const GET_PARTNER_DISCOUNT_STATUS = 'GET_PARTNER_DISCOUNT_STATUS'
