import {
  CLEAR_TAGS_AND_CATEGORY,
  FILTER_DATA_TAGS,
  GET_ALL_TAGS,
  GET_ALL_TAGS_FOR_CREATE,
  GET_TAG_BY_ID
} from "../constants/constants"

const initialState = {
    tags: {},
    tagsForCreate: [],
    tagsForUbdate:{},
    filter: {
      page: 1,
      name: ''
    }
}

export const GetAllTegs = (state = initialState, action) => {
    switch (action.type) {
        case GET_ALL_TAGS:
            return {
                ...state,
                tags: { ...action.payload }
            }
        case GET_ALL_TAGS_FOR_CREATE:
            return {
                ...state,
                tagsForCreate: action.payload.items
            }
        case GET_TAG_BY_ID:
            return {
                ...state,
                tagsForUbdate: action.payload
            }
        case CLEAR_TAGS_AND_CATEGORY:
            return {
                ...state,
                tagsForUbdate:{}
            }
      case FILTER_DATA_TAGS:
        return {
          ...state,
          filter: { ...action.payload }
        }
        default:
            return state
    }
}

