import { combineReducers } from 'redux'

import { changeState } from './changeState'
import getDataUserById from './getDataUserById'
import getLoadApplications from './loadApplication'
import getListIdentificationUser from './listIdentificationsUser'
import { GetAllTegs } from './getAllTegs'
import { GetAllCategory } from './getAllCategory'
import { getListPartners } from './partners'
import { getListPostAccounts } from './postAccounts'
import { DetailDataBranch } from './branches'
import { GetDataForConnectionVideo } from './connectVideo'
import { workMalls } from './malls'
import { LoginUser } from './login'
import { HandleStaff } from './staff'
import { loadingDataToStorage } from './loading'
import { GetInquiries } from './inquiries'
import { SaveLoadAppFilter } from './filterLoadApp'
import { getListPromotion } from './promotion'
import { AllCommentById } from './comments'
import { BonusesReduce } from './referals'
import { UserListForReports } from './reports'
import { ListBonusesSystems } from './bonusesSystems'
import news from './news'
import settlements from './settlements'
import feedback from './feedback'
import getListIdentificationPartner from './listIdentificationPartner'
import getDataPartnerById from './getDataPartnerById'
import getFuelLoanApplications from './fuelLoanApplications'
import { GetPartnerNotifications } from './partnerNotifications'
import { GetUsers } from './users'
import { PartnerBonuses } from './partnerBonuses'

export const rootReducer = combineReducers({
  changeState,
  identificationUsers: getListIdentificationUser,
  dataUserforIdentification: getDataUserById,
  dataFuelLoanApplications: getFuelLoanApplications,
  identificationPartners: getListIdentificationPartner,
  dataPartnerforIdentification: getDataPartnerById,
  loadApplications: getLoadApplications,
  partnerNotifications: GetPartnerNotifications,
  tags: GetAllTegs,
  partnerBonuses: PartnerBonuses,
  category: GetAllCategory,
  partners: getListPartners,
  postAccounts: getListPostAccounts,
  detailBranch: DetailDataBranch,
  dataConnectForVideo: GetDataForConnectionVideo,
  malls: workMalls,
  LoginUser,
  users: GetUsers,
  staff: HandleStaff,
  isLoading: loadingDataToStorage,
  inquiries: GetInquiries,
  SaveLoadAppFilter,
  promotion: getListPromotion,
  AllCommentById,
  bonuses: BonusesReduce,
  bonusesSystems: ListBonusesSystems,
  UserListForReports,
  news,
  settlements,
  feedback,
})
