import API from 'src/redux/API'
import { GET_FEEDBACK, GET_FEEDBACK_BY_ID } from '../constants/constants'
import { toast } from 'react-toastify'

const initialState = {
  feedback: {},
  oneFeedback: {},
}

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_FEEDBACK: {
      return {
        ...state,
        feedback: action.payload,
      }
    }
    case GET_FEEDBACK_BY_ID:
      return {
        ...state,
        oneFeedback: action.payload,
      }
    default:
      return state
  }
}

export const getFeedBack = (page = 1) => {
  return async (dispatch) => {
    await API.getFeedbacks(page).then(({ data }) => {
      return dispatch({ type: GET_FEEDBACK, payload: data })
    })
  }
}

export const getFeedBackById = (id) => {
  return async (dispatch) => {
    await API.getFeedById(id)
      .then(({ data }) => dispatch({ type: GET_FEEDBACK_BY_ID, payload: data }))
      .catch(() => console.log('error on try get one id'))
  }
}

export const lockFeedBack = (id) => {
  return async (dispatch) => {
    await API.lockFeedBacks(id)
      .then(({ data }) => {
        toast.success('Взят на обработку!')
        dispatch(getFeedBackById(id))
      })
      .catch(() => console.log('error on try get one id'))
  }
}
export const markFeedBack = (id) => {
  return async (dispatch) => {
    await API.markFeedBacks(id)
      .then(({ data }) => {
        toast.success('Подтвержден')
        dispatch(getFeedBackById(id))
      })
      .catch(() => console.log('error on try get one id'))
  }
}
