import {
  FILTER_DATA_IDENTIFICATE,
  GET_IDENTIFICATION_LIST_USER_MY,
  GET_IDENTIFICATION_LIST_USER,
  GET_IDENTIFICATION_LIST_USER_ADMIN,
  GET_TOTAL_COUNT_PENDING,
  GET_TOTAL_COUNT_PHOTO,
} from '../constants/constants'

const initialState = {
  totalCount: {
    Pending: 0,
    PhotosApproved: 0,
  },
  users: {
    Pending: {
      name: 'В ожидании',
      items: [],
    },
    you: {
      name: 'Мои',
      items: [],
    },
  },
  filter: {
    page: 1,
    pin: '',
    status: '',
    phone: '',
  },
  listTable: {},
}

const getListIdentificationUser = (state = initialState, action) => {
  // const idUser = getSessionStorage('profile')?.sub
  switch (action.type) {
    case GET_IDENTIFICATION_LIST_USER:
      return {
        ...state,
        users: {
          ...state.users,
          Pending: {
            ...state.users.Pending,
            items: action.payload.items,
            //.filter((el) => ['Pending', 'VideoIdentificationRequested'].includes(el.status) && !el.photoIdentificationProcessorName && !el.videoIdentificationProcessorName )
          },
          // you: { разделил на отдельный запрос
          //     ...state.users.you,
          //     items: action.payload.items.filter((el) => (el.processedBy === idUser && el.status === 'Pending') || ((el.status === 'VideoIdentificationRequested' || el.status === 'PhotosApproved') && el.videoIdentificationProcessedBy === idUser))
          //
          // }
        },
      }
    case GET_IDENTIFICATION_LIST_USER_MY: {
      return {
        ...state,
        users: {
          ...state.users,
          you: {
            ...state.users.you,
            items: action.payload.items,
          },
        },
      }
    }
    case GET_IDENTIFICATION_LIST_USER_ADMIN:
      return {
        ...state,
        listTable: action.payload,
      }

    case 'CHANGE_CARDS':
      return {
        ...state,
        users: action.payload,
      }
    case GET_TOTAL_COUNT_PENDING:
      return {
        ...state,
        totalCount: {
          ...state.totalCount,
          Pending: action.payload,
        },
      }

    case FILTER_DATA_IDENTIFICATE:
      return {
        ...state,
        filter: { ...action.payload },
      }

    case GET_TOTAL_COUNT_PHOTO:
      return {
        ...state,
        totalCount: {
          ...state.totalCount,
          PhotosApproved: action.payload,
        },
      }

    default:
      return state
  }
}

export default getListIdentificationUser
