import {
  GET_PARTNER_NOTIFICATIONS,
  GET_PARTNER_NOTIFICATION_BY_ID,
  SAVE_PAGINATION_PARTNER_NOTIFICATIONS,
} from '../constants/constants'

const initialState = {
  list: null,
  info: null,
  filter: {
    page: 1,
  },
}

export const GetPartnerNotifications = (state = initialState, action) => {
  switch (action.type) {
    case GET_PARTNER_NOTIFICATIONS:
      return {
        ...state,
        list: action.payload,
      }
    case GET_PARTNER_NOTIFICATION_BY_ID:
      return {
        ...state,
        info: action.payload,
      }
    case SAVE_PAGINATION_PARTNER_NOTIFICATIONS:
      return {
        ...state,
        filter: { ...action.payload },
      }
    default:
      return state
  }
}
