import React from 'react'
import { useEffect } from 'react'
import './style.scss'

const Logout = () => {
  useEffect(() => {
    sessionStorage.clear()
    localStorage.clear()
    Storage.clear()
  }, [])
  return (
    <div className="logout">
      <h1>Logout</h1>
    </div>
  )
}
export default Logout
