import instance from './axiosConfig'
/* eslint-disable */
export default {
  // ! User Identification
  getListIdentificationUser: (page, pin, status, phone) =>
    instance.get(
      `/Administration/api/v1/IdentificationRequests/List?phoneNumber=${phone}&pin=${pin}${
        status === 'archive'
          ? '&isSuspended=true'
          : status !== ''
          ? `&isSuspended=false${phone == '' && pin == '' ? '&statusList=' + status : ``}`
          : '&isSuspended=false&statusList=Pending&statusList=VideoIdentificationRequested'
      }&pageNumber=${page}&orderBy=updatedAt DESC,createdAt DESC&pageSize=20`,
    ),
  getListIdentificationUserCount: (page, pin, status, phone) =>
    instance.get(
      `/Administration/api/v1/IdentificationRequests/List?&isSuspended=false&hasCurrentProcessor=false&phoneNumber=${phone}&pin=${pin}${
        status !== ''
          ? `&isSuspended=false&statusList=${status}`
          : '&isSuspended=false&statusList=Pending&statusList=VideoIdentificationRequested'
      }&pageNumber=${page}&orderBy=updatedAt DESC,createdAt DESC&pageSize=20`,
    ),
  filterListIdentificationUser: (page, pin, phone, isOperator) =>
    instance.get(
      `/Administration/api/v1/IdentificationRequests/List?phoneNumber=${phone}&pin=${pin}&isSuspended=false&pageNumber=${page}&orderBy=updatedAt DESC,createdAt DESC&pageSize=20`,
    ),
  getDataForDragAndDrop: () =>
    instance.get(
      `/Administration/api/v1/IdentificationRequests/List?isSuspended=false&statusList=Pending&statusList=VideoIdentificationRequested&hasCurrentProcessor=false&orderBy=updatedAt DESC,createdAt DESC&pageNumber=1&pageSize=10000000`,
    ),

  getDataForDragAndDropMy: (id) =>
    instance.get(
      `/Administration/api/v1/IdentificationRequests/List?isSuspended=false&statusList=Pending&statusList=VideoIdentificationRequested&orderBy=updatedAt DESC,createdAt DESC&processorId=${id}&pageNumber=1&pageSize=1000000`,
    ),
  getPartner006Discounts: (id) =>
    instance.get(
      `${process.env.REACT_APP_SLOWPOKE_SERVICE}/admin/api/v1/contractors/${id}/discounts?&pageNumber=1&pageSize=1000000`,
    ),
  getPartnerDiscountStatus: (id) =>
    instance.get(`${process.env.REACT_APP_SLOWPOKE_SERVICE}/admin/api/v1/contractors/${id}`),
  addPartner006Discount: (id, data) =>
    instance.post(
      `${process.env.REACT_APP_SLOWPOKE_SERVICE}/admin/api/v1/contractors/${id}/discounts`,
      data,
    ),
  addPartnerTo006: (data) =>
    instance.post(`${process.env.REACT_APP_SLOWPOKE_SERVICE}/admin/api/v1/contractors`, data),
  getDataUserById: (id) => instance.get(`/Administration/api/v1/IdentificationRequests/Get/${id}`),
  postLogUserById: (id) =>
    instance.post(`/Administration/api/v1/IdentificationRequests/Lock/${id}`),
  getPasswordDataForIdentificate: (pin, documentType, documentNumber) =>
    instance.get(
      `/Administration/api/v1/PersonalData/Search?pin=${pin}&documentType=${documentType}&documentNumber=${documentNumber}`,
    ),
  getImageUser: (id, imageKey) =>
    instance.get(`/Administration/api/v1/IdentificationRequests/${id}/PassportImage/${imageKey}`),
  approveUser: (id, data) =>
    instance.post(`/Administration/api/v1/IdentificationRequests/Approve/${id}`, data),
  declineUser: (id, data) =>
    instance.post(`/Administration/api/v1/IdentificationRequests/Decline/${id}`, data),
  filterListLoadApplications: (page, filterObj) =>
    instance.get(
      `/Administration/api/v1/LoanApplications/List?${
        filterObj.status !== '' ? `&statusList=${filterObj.status}` : ''
      }&name=${filterObj.name}&surname=${filterObj.surname}&patronymic=${
        filterObj.patronymic
      }&phoneNumber=${filterObj.phone}&isLimitUpgrade=${
        filterObj.checkedLimit
      }&orderBy=Id DESC&pageNumber=${page}&pageSize=20`,
    ),
  sendUserFileILoanApp: (data) => instance.post(`/administration/api/v1/documents`, data),

  //! Loan Applications

  getUserFileILoanApp: (id) => instance.get(`/administration/api/v1/documents/${id}`),
  getListLoadApplications: (page) =>
    instance.get(
      `/Administration/api/v1/LoanApplications/List?&orderBy=Id DESC&pageNumber=${page}&pageSize=20`,
    ),
  getTotalCountLoadApp: (isIncrease = false) =>
    instance.get(
      `/Administration/api/v1/LoanApplications/List?statusList=Pending&isLimitUpgrade=${isIncrease}&orderBy=createdAt DESC&pageNumber=1&pageSize=1`,
    ),
  getLoanUserIncreaseLimit: (pin, status) =>
    instance.get(
      `/Administration/api/v1/LoanApplications/List?pin=${pin}${
        status ? '&statusList=Approved' : ''
      }&isLimitUpgrade=true&orderBy=createdAt DESC&pageNumber=1&pageSize=200000`,
    ),
  getLoanUser: (pin, status) =>
    instance.get(
      `/Administration/api/v1/LoanApplications/List?pin=${pin}${
        status ? '' : '&statusList=Approved'
      }&isLimitUpgrade=false&orderBy=createdAt DESC&pageNumber=1&pageSize=200000`,
    ),
  getDataUserLoadApplication: (id) =>
    instance.get(`/Administration/api/v1/LoanApplications/Get/${id}`),
  declineAndSendMessage: (id, data) =>
    instance.post(`/Administration/api/v1/IdentificationRequests/SetNeedToEdit/${id}`, data),
  scoringUser: (id) => instance.get(`/Administration/api/v1/loanApplications/Scoring/Get/${id}`),
  approvedLoadApplications: (id, data) =>
    instance.post(`/Administration/api/v1/LoanApplications/Approve/${id}`, data),
  declineLoadApplications: (id, data) =>
    instance.post(`/Administration/api/v1/LoanApplications/Decline/${id}`, data),
  postLogLoadApplication: (id) =>
    instance.post(`/Administration/api/v1/LoanApplications/Lock/${id}`),
  suspendIdentyficate: (id) =>
    instance.post(`/Administration/api/v1/IdentificationRequests/Suspend/${id}`),
  archiveIdentyficate: (id) =>
    instance.post(`/Administration/api/v1/IdentificationRequests/Resume/${id}`),
  closeCreditLineLoanApplication: (id) =>
    instance.get(
      `${process.env.REACT_APP_CLOSE_CREDIT_LINE_LOAN_APP}/api/v1/administration/Account/CloseCreditLine/${id}`,
    ),
  getStatusCreditLineLoanApplication: (id) =>
    instance.get(
      `${process.env.REACT_APP_CLOSE_CREDIT_LINE_LOAN_APP}/api/v1/administration/Account/CreditLine/${id}`,
    ),

  // ! Partner Identificate
  getListInentificationPartner: (page, status, name, phone, categoryId) =>
    instance.get(
      `Administration/api/v1/partner-applications?page=${page}&status=${status}&partnerCategoryId=${categoryId}&shopName=${name}&clientPhoneNumber=${phone}&orderBy=createdAt&orderType=desc&pageSize=20
      `,
    ),
  getDataPartnerById: (id) => instance.get(`/Administration/api/v1/partner-applications/${id}`),
  filterListIdentificationPartner: (page, name, phone, categoryId) =>
    instance.get(
      `Administration/api/v1/partner-applications?shopName=${name}&page=${page}&clientPhoneNumber=${phone}&partnerCategoryId=${categoryId}&orderBy=createdAt&orderType=desc&pageSize=20
      `,
    ),
  filterIdentyficatePartner: (page) =>
    instance.get(
      `Administration/api/v1/partner-applications?page=${page}&orderBy=createdAt&orderType=desc&pageSize=20
      `,
    ),
  approvePartner: (id, data) =>
    instance.put(`/Administration/api/v1/partner-applications/${id}/approve`, data),
  declinePartner: (id, data) =>
    instance.put(`/Administration/api/v1/partner-applications/${id}/decline`, data),
  declineAndSendMessagePartner: (id, data) =>
    instance.put(
      `/Administration/api/v1/partner-applications/${id}/need-to-edit
`,
      data,
    ),
  // ! Fuel Loan Applications
  filterListFuelLoanApplications: (page, filterObj) =>
    instance.get(
      `${process.env.REACT_APP_FUEL_LOAN_APP_URL}/admin/api/v1/loan-applications?statusList=${filterObj.status}&orderBy=createdAt DESC&pageNumber=${page}&pageSize=20`,
    ),
  getTotalCountFuelLoanApp: () =>
    instance.get(
      `${process.env.REACT_APP_FUEL_LOAN_APP_URL}/admin/api/v1/loan-applications?statusList=Pending&orderBy=createdAt DESC&pageNumber=1&pageSize=20`,
    ),
  getUserFuelLoanAppByUserId: (userId) =>
    instance.get(
      `${process.env.REACT_APP_FUEL_LOAN_APP_URL}/admin/api/v1/loan-applications?debtorId=${userId}&orderBy=createdAt DESC&pageNumber=1&pageSize=1`,
    ),
  getDataFuelLoanApplication: (id) =>
    instance.get(`${process.env.REACT_APP_FUEL_LOAN_APP_URL}/admin/api/v1/loan-applications/${id}`),
  scoringFuelLoanApplication: (id) =>
    instance.get(
      `${process.env.REACT_APP_FUEL_LOAN_APP_URL}/admin/api/v1/loan-applications/${id}/scoring`,
    ),
  approveFuelLoanApplication: (id, data) =>
    instance.patch(
      `${process.env.REACT_APP_FUEL_LOAN_APP_URL}/admin/api/v1/loan-applications/${id}/approve`,
      data,
    ),
  declineFuelLoanApplication: (id, data) =>
    instance.patch(
      `${process.env.REACT_APP_FUEL_LOAN_APP_URL}/admin/api/v1/loan-applications/${id}/decline`,
      data,
    ),
  postLogFuelLoanApplications: (id) =>
    instance.post(
      `${process.env.REACT_APP_FUEL_LOAN_APP_URL}/admin/api/v1/loan-applications/${id}/lock`,
    ),
  closeCreditLineFuelLoanApplication: (id) =>
    instance.post(
      `${process.env.REACT_APP_FUEL_LOAN_APP_URL}/admin/api/v1/credit-lines/${id}/close`,
    ),
  getDebtorsFuelLoanApp: (id) =>
    instance.get(`${process.env.REACT_APP_FUEL_LOAN_APP_URL}/admin/api/v1/debtors/${id}`),
  needToEditFuelLoanApplication: (data) =>
    instance.patch(
      `${process.env.REACT_APP_FUEL_LOAN_APP_URL}/admin/api/v1/loan-applications/${data.id}/need-to-edit`,
      {
        comment: data.comment,
      },
    ),
  fuelCreditInformation: (id) =>
    instance.get(
      `${process.env.REACT_APP_FUEL_LOAN_APP_URL}/admin/api/v1/loan-applications/${id}/report`,
      {
        responseType: 'blob',
      },
    ),
  getNeedToEditCommentById: (id) =>
    instance.get(
      `${process.env.REACT_APP_FUEL_LOAN_APP_URL}/admin/api/v1/loan-applications/${id}/validation-issues`,
    ),
  resetStatusFuelLoanApplication: (id) =>
    instance.patch(
      `${process.env.REACT_APP_FUEL_LOAN_APP_URL}/admin/api/v1/loan-applications/${id}/reset`,
    ),
  // ! PartnerNotifications
  getPartnerNotifications: (page) =>
    instance.get(
      `${process.env.REACT_APP_PARTNER_NOTIFICATIONS}/notifications?pageNumber=${page}&pageSize=20`,
    ),
  getPartnerNotificationById: (id) =>
    instance.get(`${process.env.REACT_APP_PARTNER_NOTIFICATIONS}/admin/notifications/${id}`),
  createPartnerNotification: (data) =>
    instance.post(`${process.env.REACT_APP_PARTNER_NOTIFICATIONS}/admin/notifications`, data),
  updatePartnerNotification: (id, data) =>
    instance.patch(
      `${process.env.REACT_APP_PARTNER_NOTIFICATIONS}/admin/notifications/${id}`,
      data,
    ),
  deletePartnerNotification: (id) =>
    instance.delete(`${process.env.REACT_APP_PARTNER_NOTIFICATIONS}/admin/notifications/${id}`),
  // ! Offline identification
  getUsersList: (page) => instance.get(`/administration/api/v2/users?pageNumber=${page}`),

  getUserByPhoneNumber: (phoneNumber) =>
    instance.get(`/administration/api/v2/users?phoneNumber=${phoneNumber}`),

  getUserDetailById: (id) => instance.get(`/administration/api/v2/users/${id}`),
  offlineIdentificateUser: (id) =>
    instance.post(`/administration/api/v2/users/${id}/offline-identification-level`),
  filterListUsers: (page, filterObj) =>
    instance.get(
      `/administration/api/v2/users?${
        filterObj.status !== '' ? `identificationLevels=${filterObj.status}` : ''
      }&name=${filterObj.name}&surname=${filterObj.surname}&patronymic=${
        filterObj.patronymic
      }&phoneNumber=${filterObj.phone}&orderBy=Id DESC&pageNumber=${page}&pageSize=20`,
    ),
  getUserOfflineIdentificateFiles: (id) => instance.get(`/Administration/api/v2/users/${id}/files`),
  sendUserOfflineIdentificateFile: (id, data) =>
    instance.post(`/Administration/api/v2/users/${id}/files`, data),
  // !
  getUserRelatedFiles: (id) => instance.get(`/administration/api/v1/user-related-files/${id}`),
  getDetailUser: (id) => instance.get(`/Administration/api/v1/Users/Details/${id}`),
  needToEditLoanApp: (data) =>
    instance.post(`/Administration/api/v1/LoanApplications/NeedToEdit/${data.id}`, {
      comment: data.comment,
    }),

  getTags: (page, name) =>
    instance.get(`/api/v1/PartnerTags/Search?name=${name}&pageNumber=${page}&pageSize=20`),
  getTagsPartner: (page) =>
    instance.get(`/api/v1/PartnerTags/Search?pageNumber=${page}&pageSize=100000000`),
  getCategory: (page, name, pageSize) =>
    instance.get(
      `/api/v1/PartnerCategories/Search?name=${name}&pageNumber=${page}&pageSize=${pageSize}`,
    ),

  createPartner: (data) => instance.post(`/Administration/api/v1/Partners/Create`, data),
  createTags: (data) => instance.post(`/Administration/api/v1/PartnerTags/Create`, data),
  getTagsById: (id) => instance.get(`/Administration/api/v1/PartnerTags/Get/${id}`),

  getListPartners: (count, page, name, categories, localityId) =>
    instance.get(
      `/api/v1/Partners/Search?orderBy=createdAt DESC${name ? `&name=${name}` : ''}${
        categories.length ? categories.map((el) => `&categories=${el}`).join('') : ''
      }&localityId=${localityId}&pageNumber=${page}&pageSize=${count}`,
    ),
  getPartnerById: (id) => instance.get(`/Administration/api/v1/Partners/Get/${id}`),

  getCateryById: (id) => instance.get(`/Administration/api/v1/PartnerCategories/Get/${id}`),
  getListCategory: (page) =>
    instance.get(`/api/v1/PartnerCategories/Search?pageNumber=${page}&pageSize=20`),
  createCategory: (data) => instance.post(`/Administration/api/v1/PartnerCategories/Create`, data),
  deleteCategory: (id) => instance.delete(`/Administration/api/v1/PartnerCategories/Remove/${id}`),
  updateCategory: (data, id) =>
    instance.put(`/Administration/api/v1/PartnerCategories/Update/${id}`, data),
  addBranches: (data) => instance.post(`/Administration/api/v1/PartnerBranches/Create`, data),
  updateTags: (data, id) => instance.put(`/Administration/api/v1/PartnerTags/Update/${id}`, data),
  deleteTags: (id) => instance.delete(`/Administration/api/v1/PartnerTags/Remove/${id}`),

  getPostAccountsByIdBranches: (id) =>
    instance.get(`/Administration/api/v1/PartnerBranches/${id}/PosAccounts/List`),
  addPostAccounts: (id, data) =>
    instance.post(`/Administration/api/v1/PartnerBranches/${id}/PosAccounts/Add`, data),
  updatePasswordPostAccount: (idBranches, idAccounts, data) =>
    instance.put(
      `/Administration/api/v1/PartnerBranches/${idBranches}/PosAccounts/UpdateСredentials/${idAccounts}`,
      data,
    ),
  deletePostAccount: (idBranches, idAccounts) =>
    instance.delete(
      `/Administration/api/v1/PartnerBranches/${idBranches}/PosAccounts/Delete/${idAccounts}`,
    ),
  unBlockPostAccount: (idBranches, id) =>
    instance.post(
      `/Administration/api/v1/PartnerBranches/${idBranches}/PosAccounts/ResetLockout/${id}`,
    ),
  deleteBranches: (id) => instance.delete(`/Administration/api/v1/PartnerBranches/Delete/${id}`),

  detailBranch: (id) => instance.get(`/Administration/api/v1/PartnerBranches/Get/${id}`),
  editBranch: (id, data) =>
    instance.put(`/Administration/api/v1/PartnerBranches/Update/${id}`, data),
  videoIdentification: (idIdentification) =>
    instance.get(`/Administration/api/v1/VideoIdentification/GetToken/${idIdentification}`),
  getListMalls: (page, pageSize) =>
    instance.get(`/api/v1/Malls/Search?&pageNumber=${page}&pageSize=${pageSize}`),
  getListMallsForPartners: (id, page) =>
    instance.get(`/api/v1/Malls/Search?localityId=${id}&pageNumber=${page}&pageSize=200000`),
  createMall: (data) => instance.post(`/Administration/api/v1/Malls/Create`, data),
  deleteMall: (id) => instance.delete(`/Administration/api/v1/Malls/Remove/${id}`),
  getDetailDataMall: (id) => instance.get(`/api/v1/Malls/Get/${id}`),
  updateMall: (data, id) => instance.put(`/Administration/api/v1/Malls/Update/${id}`, data),
  approvePhoto: (id, data) =>
    instance.post(`/Administration/api/v1/IdentificationRequests/ApprovePhotos/${id}`, data),

  getListStaff: (page) =>
    instance.get(
      `${process.env.REACT_APP_BASE_URL_OIDC}/Administration/api/v1/Users/Search?&pageNumber=${page}&pageSize=21`,
    ),
  createStaff: (data) =>
    instance.post(
      `${process.env.REACT_APP_BASE_URL_OIDC}/Administration/api/v1/Users/Create`,
      data,
    ),
  getListRole: () =>
    instance.get(`${process.env.REACT_APP_BASE_URL_OIDC}/Administration/api/v1/Roles/List`),
  getDetailStaff: (id) =>
    instance.get(`${process.env.REACT_APP_BASE_URL_OIDC}/Administration/api/v1/Users/Get/${id}`),
  deleteStaff: (id) =>
    instance.delete(
      `${process.env.REACT_APP_BASE_URL_OIDC}/Administration/api/v1/Users/Delete/${id}`,
    ),
  changePasswordStaff: (id, data) =>
    instance.post(
      `${process.env.REACT_APP_BASE_URL_OIDC}/Administration/api/v1/Users/ChangePassword?id=${id}`,
      data,
    ),
  updateStaff: (id, data) =>
    instance.put(
      `${process.env.REACT_APP_BASE_URL_OIDC}/Administration/api/v1/Users/Update/${id}`,
      data,
    ),
  creditInformation: (id) =>
    instance.get(`/Administration/api/v1/LoanApplications/${id}/CreditInformationReport`, {
      responseType: 'blob',
    }),
  updatePartner: (id, data) => instance.put(`/Administration/api/v1/Partners/Update/${id}`, data),

  listPartnerAccount: (id) =>
    instance.get(`/Administration/api/v1/Partners/${id}/PartnerAccounts/List`),
  changePartnerAccount: (partnerId, accountId, data) =>
    instance.post(
      `/Administration/api/v1/Partners/${partnerId}/PartnerAccounts/ChangePassword/${accountId}`,
      data,
    ),
  deletePartnerAccount: (partnerId, accountId) =>
    instance.delete(
      `/Administration/api/v1/Partners/${partnerId}/PartnerAccounts/Delete/${accountId}`,
    ),
  createPartnerAccount: (partnerId, data) =>
    instance.post(`/Administration/api/v1/Partners/${partnerId}/PartnerAccounts/Create`, data),

  getDataGns: (pin) =>
    instance.get(`/Administration/api/v1/TaxInspection/SocialFundBalance/${pin}`),
  getTaxPayerDataByPin: (pin) =>
    instance.get(`/Administration/api/v1/TaxInspection/GetTaxPayerDataByPin/${pin}`),

  getBlackListPerson: (fullname) =>
    instance.get(
      `/Administration/api/v1/financialintelligence/BlackListPerson/Search?fullname=${fullname}`,
    ),

  getSocFont: (pin) => instance.get(`/Administration/api/v1/socialfund/Deductions/Get/${pin}`),

  logsInquiries: (name, pageNumber, date) =>
    instance.get(
      `${
        process.env.REACT_APP_LOGS_URL
      }/api/v1/logs/search?args.ExternalCreationDateTimeFromInclusive=${
        date.length !== 0 ? date[0] : ''
      }&args.ExternalCreationDateTimeToInclusive=${
        date.length !== 0 ? date[1] : ''
      }&args.TargetName=${name}&pageSize=20&pageNumber=${pageNumber}`,
    ),
  filterIdentyficate: (page, isOperator) =>
    instance.get(
      `/Administration/api/v1/IdentificationRequests/List?isSuspended=false&${
        !isOperator
          ? 'statusList=Pending&statusList=VideoIdentificationRequested&'
          : 'statusList=VideoIdentificationRequested&statusList=NeedToEdit&statusList=Pending&statusList=PhotosApproved&'
      }orderBy=Id%20DESC&pageNumber=${page}&pageSize=20`,
    ),
  deleteSessionOpenVidu: (sessionId) =>
    instance.delete(`/Administration/api/v1/VideoIdentification/CloseSession/${sessionId}`),
  updateLoadApp: ({ id, requestingAmount }) =>
    instance.put(`/Administration/api/v1/LoanApplications/Update/${id}`, { requestingAmount }),
  resetStatusLoadApp: (id) =>
    instance.post(`/Administration/api/v1/LoanApplications/ResetStatus/${id}`),
  //  !
  promotionList: (page) =>
    instance.get(
      `${process.env.REACT_APP_PROMOTION_URL}/Admin/Api/V1/Promotions/List?pageNumber=${page}&pageSize=20`,
    ),
  uploadFile: (file) =>
    instance.post(`${process.env.REACT_APP_PROMOTION_URL}/Admin/Api/v1/Images/Upload`, file, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    }),

  createPromotion: (data) =>
    instance.post(`${process.env.REACT_APP_PROMOTION_URL}/Admin/Api/V1/Promotions/Create`, data),
  removePromotion: (id) =>
    instance.delete(`${process.env.REACT_APP_PROMOTION_URL}/Admin/Api/V1/Promotions/Delete/${id}`),
  getMoreInfoPromotion: (id) =>
    instance.get(`${process.env.REACT_APP_PROMOTION_URL}/Admin/Api/V1/Promotions/Get/${id}`),
  updatePromotion: (id, data) =>
    instance.put(
      `${process.env.REACT_APP_PROMOTION_URL}/Admin/Api/V1/Promotions/Update/${id}`,
      data,
    ),

  getAllComments: (id) =>
    instance.get(`/Administration/api/v1/LoanApplications/${id}/Comments/List`),
  sendComment: (id, content) =>
    instance.post(`/Administration/api/v1/LoanApplications/${id}/Comments/Create`, { content }),
  // ! Bonuses
  getListBonuses: (pageNumber) =>
    instance.get(
      `/Administration/api/v1/BonusWithdrawalRequests/List?orderBy=CreatedAt%20DESC&pageNumber=${pageNumber}&pageSize=20`,
    ),
  approveBonuse: (id) =>
    instance.patch(`/Administration/api/v1/BonusWithdrawalRequests/Approve/${id}`),
  declineBonuse: (id) =>
    instance.patch(`/Administration/api/v1/BonusWithdrawalRequests/Decline/${id}`),
  getListForBonusesSystems: (page) =>
    instance.get(
      `/api/v1/referral-program/BonusWithdrawalRequests/List?orderBy=CreatedAt%20DESC&pageNumber=${page}&pageSize=20`,
    ),
  createBonusSystems: (data) =>
    instance.post(`/api/v1/referral-program/BonusWithdrawalRequests/Create`, data),

  // ! Partner Bonuses
  getPartnerByExternalIdBonuses: (id) =>
    instance.get(
      `${process.env.REACT_APP_ACCOUNTS_OIDC}/admin/api/v1/contractors?externalId=${id}&pageNumber=1&pageSize=20`,
    ),
  getListPartnerBonuses: (pageNumber) =>
    instance.get(
      `${process.env.REACT_APP_ACCOUNTS_OIDC}/admin/api/v1/contractors?pageNumber=${pageNumber}&pageSize=20`,
    ),
  deletePercentagePartnerBonuses: (id, percentageId) =>
    instance.delete(
      `${process.env.REACT_APP_ACCOUNTS_OIDC}
/admin/api/v1/contractors/${id}/percentages/${percentageId}`,
    ),
  getPercentagePartnerBonuses: (id, page) =>
    instance.get(
      `${process.env.REACT_APP_ACCOUNTS_OIDC}/admin/api/v1/contractors/${id}/percentages?pageNumber=${page}&pageSize=9999`,
    ),
  addPercentagePartnerBonuses: (id, data) =>
    instance.post(
      `${process.env.REACT_APP_ACCOUNTS_OIDC}/admin/api/v1/contractors/${id}/percentages`,
      data,
    ),
  getDetailPartnerBonuses: (id) =>
    instance.get(`${process.env.REACT_APP_ACCOUNTS_OIDC}/admin/api/v1/contractors/${id}`),
  deletePartnerBonuses: (id) =>
    instance.delete(`${process.env.REACT_APP_ACCOUNTS_OIDC}/admin/api/v1/contractors/${id}`),
  createPartnerBonuses: (data) =>
    instance.post(`${process.env.REACT_APP_ACCOUNTS_OIDC}/admin/api/v1/contractors`, data),

  // ! Oidc
  getUserListOidc: (page, phone) =>
    instance.get(
      `${
        process.env.REACT_APP_BASE_CLIENT_IDP || 'https://stage.c2u.io:1001'
      }/Admin/Api/V1/Users/List?PhoneNumber=${phone}&IsIdentified=true&pageNumber=${page}&pageSize=20`,
    ),
  getListAccountForUserOidc: (page, id) =>
    instance.get(
      `${
        process.env.REACT_APP_ACCOUNTS_OIDC || 'https://stage.c2u.io:7777'
      }/admin/api/v1/accounts/${id}`,
    ),
  createAccountOidc: (data, id) =>
    instance.post(
      `${
        process.env.REACT_APP_ACCOUNTS_OIDC || 'https://stage.c2u.io:7777'
      }/admin/api/v1/accounts/${id}/add-bonus`,
      data,
    ),
  // !
  listUsersForReports: (
    hasIdentificationRequests,
    hasLoanApplicationRequests,
    startDate,
    endDate,
  ) =>
    instance.get(
      `/Administration/api/v1/Users/Search?hasIdentificationRequests=${hasIdentificationRequests}&createdAtFromInclusive=${startDate}&createdAtToInclusive=${endDate}&hasLoanApplicationRequests=${hasLoanApplicationRequests}&pageNumber=1&pageSize=100000`,
    ),
  //  ! News
  getListNews: (page, value) =>
    instance.get(
      `${process.env.REACT_APP_PROMOTION_URL}/Admin/Api/v1/News/List?${
        value ? `Title=${value}&` : ''
      }pageNumber=${page}&pageSize=20`,
    ),
  deleteNews: (id) =>
    instance.delete(`${process.env.REACT_APP_PROMOTION_URL}/Admin/Api/v1/News/Delete/${id}`),
  createNews: (data) =>
    instance.post(`${process.env.REACT_APP_PROMOTION_URL}/Admin/Api/v1/News/Create`, data),
  getNewsById: (id) =>
    instance.get(`${process.env.REACT_APP_PROMOTION_URL}/Admin/Api/v1/News/Get/${id}`),
  updateNews: (id, data) =>
    instance.put(`${process.env.REACT_APP_PROMOTION_URL}/Admin/Api/v1/News/Update/${id}`, data),

  deletePartner: (id) => instance.delete(`/Administration/api/v1/Partners/Delete/${id}`),
  createFromDraftPartner: (id, data) =>
    instance.put(`/Administration/api/v1/Partners/CreateFromDraft/${id}`, data),
  //  ! Settlements
  getSettlements: (page, name) =>
    instance.get(`/api/v1/Localities/Search?name=${name}&pageNumber=${page}&pageSize=20000`),
  createSettlements: (data) => instance.post(`/Administration/api/v1/Localities/Create`, data),
  deleteSettlements: (id) => instance.delete(`/Administration/api/v1/Localities/Delete/${id}`),
  getSettlementsById: (id) => instance.get(`/api/v1/Localities/Get/${id}`),
  updateSettlements: (data, id) =>
    instance.put(`/Administration/api/v1/Localities/Update/${id}`, data),
  //  ! Feedbacks
  getFeedbacks: (page) =>
    instance.get(
      `Administration/Api/V1/Feedbacks?orderBy=CreatedAt%20DESC&pageNumber=${page}&pageSize=20`,
    ),
  getFeedById: (id) => instance.get(`Administration/Api/V1/Feedbacks/${id}`),
  lockFeedBacks: (id) => instance.post(`Administration/Api/V1/Feedbacks/${id}/Lock`, { id }),
  markFeedBacks: (id) =>
    instance.post(`Administration/Api/V1/Feedbacks/${id}/MarkAsProcessed`, { id }),
}
