import { GET_DATA_DETAIL_BRANCH } from "../constants/constants"

const initialState = {
    detailBranch: {}
}

export const DetailDataBranch = (state = initialState, action) => {
    switch(action.type){
        case GET_DATA_DETAIL_BRANCH:
            return {
                ...state,
                detailBranch:{...action.payload}
            }
        default:
            return state
    }
}