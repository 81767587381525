import { FILTER_DATA_BONUSES } from '../constants/constants'

const initialState = {
  partnerBonuses: {},
  partnerBonuseDetail: {},
  partnerPercentages: {},
  filter: {
    page: 1,
  },
}

export const PartnerBonuses = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_LIST_PARTNER_BONUSES':
      return {
        ...state,
        partnerBonuses: action.payload,
      }
    case 'GET_DETAIL_PARTNER_BONUSES':
      return {
        ...state,
        partnerBonuseDetail: action.payload,
      }
    case 'GET_PERCENTAGE_PARTNER_BONUSES':
      return {
        ...state,
        partnerPercentages: action.payload,
      }
    case FILTER_DATA_BONUSES:
      return {
        ...state,
        filter: { ...action.payload },
      }
    default:
      return state
  }
}
