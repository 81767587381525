import { GET_INQUIRIES } from "../constants/constants"

const initialState = {
    inquiries: {},
}

export const GetInquiries = (state = initialState, action) => {
    switch (action.type) {
        case GET_INQUIRIES:
            return {
                ...state,
                inquiries: { ...action.payload },
               
            }
        default:
            return state
    }
}