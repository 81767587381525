const initialState = {
  login: false,
}

export const LoginUser = (state = initialState, action) => {
  switch (action.type) {
    case 'LOGIN':
      return {
        login: true,
      }
    case 'LOGOUT':
      return {
        login: false,
      }
    default:
      return state
  }
}
