import {
  CLEAR_STORE_PARTNER_DATA,
  GET_LIST_ACCOUNTS_FOR_PARTNER,
  GET_LIST_PARTNERS,
  GET_PARTNER_BY_ID,
  GET_PARTNER_DISCOUNTS,
  GET_PARTNER_DISCOUNT_STATUS,
  PARTNER_TAB,
} from '../constants/constants'

const initialState = {
  arrPartners: {},
  moreDataPartner: {},
  listAccounts: [],
  tabs: 1,
}

export const getListPartners = (state = initialState, action) => {
  switch (action.type) {
    case GET_LIST_PARTNERS:
      return {
        ...state,
        arrPartners: action.payload,
      }
    case GET_PARTNER_BY_ID:
      return {
        ...state,
        moreDataPartner: action.payload,
      }
    case GET_PARTNER_DISCOUNTS:
      return {
        ...state,
        partnerDiscounts: action.payload,
      }
    case GET_PARTNER_DISCOUNT_STATUS:
      return {
        ...state,
        partnerDiscountStatus: action.payload,
      }
    case CLEAR_STORE_PARTNER_DATA:
      return {
        ...state,
        moreDataPartner: {},
      }
    case GET_LIST_ACCOUNTS_FOR_PARTNER:
      return {
        ...state,
        listAccounts: [...action.payload],
      }
    case PARTNER_TAB:
      return {
        ...state,
        tabs: action.payload,
      }
    default:
      return state
  }
}
