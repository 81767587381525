import { FILTER_DATA_LOAD_APP } from '../constants/constants'

const initialState = {
  name: '',
  surname: '',
  patronymic: '',
  status: 'Pending',
  phone: '',
  page: 1,
  checkedLimit: true,
}

export const SaveLoadAppFilter = (state = initialState, actions) => {
  switch (actions.type) {
    case FILTER_DATA_LOAD_APP:
      return actions.payload
    default:
      return state
  }
}
