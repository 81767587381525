import {
  CLEAR_TAGS_AND_CATEGORY,
  FILTER_DATA_SETTLEMENT,
  GET_ALL_SETTLEMENTS,
  GET_SETTLEMENTS_BY_ID,
} from '../constants/constants'
import { toast } from 'react-toastify'
import API from '../API'

const initialState = {
  settlements: {},
  dataForUpdate: {},
  filter: {
    page: 1,
    name: '',
  },
}

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_SETTLEMENTS:
      return {
        ...state,
        settlements: { ...action.payload },
      }
    case GET_SETTLEMENTS_BY_ID:
      return {
        ...state,
        dataForUpdate: action.payload,
      }
    case CLEAR_TAGS_AND_CATEGORY:
      return {
        ...state,
        dataForUpdate: {},
      }
    case FILTER_DATA_SETTLEMENT:
      return {
        ...state,
        filter: { ...action.payload },
      }
    default:
      return state
  }
}

export const getSettlementsForPartners = (page = 1, name = '') => {
  return async (dispatch) => {
    await API.getSettlements(page, name).then((res) => dispatch(success(res.data)))
  }

  function success(json) {
    return { type: GET_ALL_SETTLEMENTS, payload: json }
  }
}

export const createSettlements = (data) => {
  return async (dispatch) => {
    await API.createSettlements(data)
      .then((res) => {
        toast.success('Населенный пункт успешно создан!')
        dispatch(getSettlementsForPartners())
      })
      .catch((err) => toast.error('Что пошло не так ('))
  }
}

export const getSettlementById = (id, callback) => {
  return async (dispatch) => {
    await API.getSettlementsById(id)
      .then((res) => {
        dispatch(success(res.data))
        if (callback) callback()
      })
      .catch((err) => console.log(err, 'asdasdasd'))
  }
  function success(json) {
    return { type: GET_SETTLEMENTS_BY_ID, payload: json }
  }
}

export const deleteSettlementById = (id, page) => {
  return async (dispatch) => {
    await API.deleteSettlements(id)
      .then((res) => {
        toast.success('Населенный пункт успешно удален!')
        dispatch(getSettlementsForPartners(page))
      })
      .catch((err) => {
        toast.error('К данному населенному пункту, привязан Торговый центр !')
        console.log(err)
      })
  }
}

export const updateSettlement = (data, id, page, callback) => {
  return async (dispatch) => {
    await API.updateSettlements(data, id)
      .then((res) => {
        toast.success('Данные успешно изменены!')
        dispatch(getSettlementsForPartners(page))
        callback(false)
      })
      .catch((err) => toast.error('Что то пошло не так ('))
  }
}

export const saveFilterSettlement = (data) => {
  return (dispatch) => {
    dispatch({ type: FILTER_DATA_SETTLEMENT, payload: data })
  }
}
