

const initialState = {
    isLoading: true
}


export const loadingDataToStorage = (state = initialState, actions) => {
    switch (actions.type) {
        case "LOGIN_TRUE":
            return {
                ...state,
                isLoading: false
            }
        case "LOGIN_FALSE":
            return {
                ...state,
                isLoading: true
            }

        default:
            return state
    }
}
