import { CLEAR_DATA_DETAIL_MALL, GET_DETAIL_DATA_MALL, GET_LIST_MALL } from "../constants/constants"

const initialState = {
    malls: {},
    detailMall:{}
}

export const workMalls = (state = initialState, action) => {
    switch (action.type) {
        case GET_LIST_MALL:
            return {
                ...state,
                malls: action.payload
            }
        case GET_DETAIL_DATA_MALL:
            return{
                ...state,
                detailMall: action.payload
            }
        case CLEAR_DATA_DETAIL_MALL:
            return{
                ...state,
                detailMall:{}
            }
        default:
            return state
    }
}

