import {
  GET_IDENTIFICATION_LIST_PARTNER,
  GET_IDENTIFICATION_LIST_PARTNER_ADMIN,
  GET_IDENTIFICATION_LIST_PARTNER_MY,
  GET_TOTAL_COUNT_PENDING_PARTNER,
  FILTER_DATA_IDENTIFICATE_PARTNER,
} from '../constants/constants'

const initialState = {
  totalCount: {
    Pending: 0,
  },
  partners: {
    Pending: {
      name: 'В ожидании',
      items: [],
    },
    you: {
      name: 'Мои',
      items: [],
    },
  },
  filter: {
    page: 1,
    status: 'Created',
    name: '',
    phone: '',
    categoryId: '',
  },
  listTable: {},
}

const getListIdentificationPartner = (state = initialState, action) => {
  switch (action.type) {
    case GET_IDENTIFICATION_LIST_PARTNER:
      return {
        ...state,
        partners: {
          ...state.partners,
          Pending: {
            ...state.partners.Pending,
            items: action.payload.items,
          },
        },
      }
    case GET_IDENTIFICATION_LIST_PARTNER_MY: {
      return {
        ...state,
        partners: {
          ...state.partners,
          you: {
            ...state.partners.you,
            items: action.payload.items,
          },
        },
      }
    }
    case GET_IDENTIFICATION_LIST_PARTNER_ADMIN:
      return {
        ...state,
        listTable: action.payload,
      }

    case GET_TOTAL_COUNT_PENDING_PARTNER:
      return {
        ...state,
        totalCount: {
          ...state.totalCount,
          Pending: action.payload,
        },
      }

    case FILTER_DATA_IDENTIFICATE_PARTNER:
      return {
        ...state,
        filter: { ...action.payload },
      }

    default:
      return state
  }
}

export default getListIdentificationPartner
