import { GET_LIST_USER_FOR_REPORTS } from '../constants/constants'


const initialState = {
    firstColumn:[],
    secondColumn:[]
}

export const UserListForReports = (state = initialState, action)=>{
    switch(action.type){
        case GET_LIST_USER_FOR_REPORTS:
            return action.payload
        default:
            return state
    }
}