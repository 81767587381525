import { GET_LIST_BONUSES } from '../constants/constants';


export const BonusesReduce = (state = {}, action)=>{
    switch(action.type){
        case GET_LIST_BONUSES:
            return action.payload
        default:
            return state
    }
}