import {
  CHANGE_AMOUNT_FUEL_LOAN_APP,
  CLEAR_FUEL_LOAN_APPLICATIONS,
  FILTER_DATA_FUEL_LOAN_APP,
  GET_BLACK_LIST_PERSON,
  GET_DATA_FUEL_SCORING,
  GET_FUEL_APP_PERSONAL_DATA,
  GET_FUEL_DATA_FROM_GNS,
  GET_FUEL_PDF_REPORT,
  GET_FUEL_SOC_FONT,
  GET_FUEL_TAXPAYER_BY_PIN,
  GET_LIST_FUEL_LOAN_APPLICATIONS,
  GET_MORE_DATA__FUEL_LOAN_APPLICATION,
  GET_NEED_TO_EDIT_COMMENT,
  GET_TOTAL_COUNT_FUEL_LOAN_APP,
  TRIGGER_LOG_FUEL_LOAN_APP,
} from '../constants/constants'

const initialState = {
  users: [],
  moreData: {
    comments: [],
  },
  personalData: {},
  scoring: {},
  creditInfo: {
    isContent: false,
    content: '',
  },
  totalCount: 0,
  images: [],
  videos: [],
  processed: false,
  filter: {
    status: 'Pending',
    page: 1,
  },
  gns: {},
  socFont: {},
  taxpayer: {},
  pdf: '',
  blackList: [],
  loanAppInfo: {
    hasLoanApp: false,
    loanAppId: null,
  },
  debtorInfo: null,
}

const getFuelLoanApplications = (state = initialState, actions) => {
  switch (actions.type) {
    case GET_LIST_FUEL_LOAN_APPLICATIONS:
      return {
        ...state,
        users: actions.payload,
      }

    case GET_MORE_DATA__FUEL_LOAN_APPLICATION:
      return {
        ...state,
        moreData: actions.payload,
      }

    case GET_FUEL_APP_PERSONAL_DATA:
      return {
        ...state,
        personalData: actions.payload,
      }
    case GET_DATA_FUEL_SCORING:
      return {
        ...state,
        scoring: actions.payload,
      }
    case GET_TOTAL_COUNT_FUEL_LOAN_APP:
      return {
        ...state,
        totalCount: actions.payload,
      }

    case TRIGGER_LOG_FUEL_LOAN_APP:
      return {
        ...state,
        processed: actions.payload === 200,
      }
    case GET_NEED_TO_EDIT_COMMENT:
      return {
        ...state,
        moreData: { ...state.moreData, comments: actions.payload },
      }
    case CLEAR_FUEL_LOAN_APPLICATIONS:
      return {
        ...state,
        users: [],
        moreData: {
          comments: [],
        },
        personalData: {},
        scoring: {},
        creditInfo: {
          isContent: false,
          content: '',
        },
        images: [],
        videos: [],
        processed: false,
        gns: {},
        socFont: {},
        taxpayer: {},
        pdf: '',
        blackList: [],
        debtorInfo: null,
      }

    case 'RES_IMAGE_FUEL_LOAN_APP':
      return {
        ...state,
        images: [...state.images, actions.payload],
      }
    case 'RES_VIDEO_FUEL_LOAN_APP':
      return {
        ...state,
        videos: actions.payload,
      }
    case FILTER_DATA_FUEL_LOAN_APP:
      return {
        ...state,
        filter: actions.payload,
      }
    case CHANGE_AMOUNT_FUEL_LOAN_APP:
      return {
        ...state,
        moreData: { ...state.moreData, requestingAmount: actions.payload },
      }
    case 'CLEAR_SORT_FUEL_APP':
      return {
        ...state,
        users: [],
      }
    case GET_FUEL_PDF_REPORT:
      return {
        ...state,
        pdf: actions.payload,
      }
    case GET_FUEL_SOC_FONT:
      return {
        ...state,
        socFont: actions.payload,
      }
    case 'FUEL_LOAN_APP_DEBTORS':
      return {
        ...state,
        debtorInfo: actions.payload,
      }
    case GET_FUEL_TAXPAYER_BY_PIN:
      return {
        ...state,
        taxpayer: actions.payload,
      }
    case GET_FUEL_DATA_FROM_GNS:
      return {
        ...state,
        gns: actions.payload,
      }

    case GET_BLACK_LIST_PERSON:
      return {
        ...state,
        blackList: actions.payload,
      }
    case 'HAS_LOAN_APP':
      return {
        ...state,
        loanAppInfo: actions.payload,
      }
    default:
      return state
  }
}
export default getFuelLoanApplications
