import {
  FILTER_DATA_BONUSES,
  GET_LIST_ACCOUNTS_USER_OIDC,
  GET_LIST_USERS_OIDC
} from "../constants/constants"

const initialState = {
    users: {},
    accounts: {},
    filter: {
      page: 1,
      phone: ''
    }
}

export const ListBonusesSystems = (state = initialState, action) => {
    switch (action.type) {
        case GET_LIST_USERS_OIDC:
            return {
                ...state,
                users: action.payload
            }
        case GET_LIST_ACCOUNTS_USER_OIDC:
            return {
                ...state,
                accounts: action.payload
            }
        case FILTER_DATA_BONUSES:
          return {
            ...state,
            filter: { ...action.payload }
          }
        default:
          return state
    }
}
