import { GET_DETAIL_STAFF, GET_LIST_ROLES, GIT_LIST_STAFF } from "../constants/constants"

const initialState = {
    listStaff: {},
    roles:[],
    detailData:{}
}

export const HandleStaff = (state = initialState, action)=>{
    switch(action.type){
        case GIT_LIST_STAFF:
            return {
                ...state,
                listStaff: {... action.payload}
            }
        case GET_LIST_ROLES:
            return {
                ...state,
                roles: action.payload
            }
        case GET_DETAIL_STAFF:
            return {
                ...state,
                detailData: action.payload
            }
        default:
            return state
    }
}