import { GET_LIST_POSTACCOUNTS } from "../constants/constants"


const initialState = {
    accounts: []
}

export const getListPostAccounts = (state = initialState, action)=>{

    switch(action.type){
        case GET_LIST_POSTACCOUNTS:
            return{
                ...state,
                accounts:[...action.payload]
            }
        default:
            return state
    }
}