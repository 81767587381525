const { GET_DATA_FOR_CONNECTION_VIDEO, CLEAR_DATA_FOR_CONNECTION_VIDEO } = require("../constants/constants");

export const GetDataForConnectionVideo = (state = {}, action) => {
    switch (action.type) {
        case GET_DATA_FOR_CONNECTION_VIDEO:
            return {
                ...state,
                data: action.payload
            }
        case CLEAR_DATA_FOR_CONNECTION_VIDEO:
            return {
                ...state,
                data: {}
            }
        default:
            return state
    }

}
