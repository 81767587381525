import { FILTER_DATA_NEWS, GET_NEWS, GET_NEWS_BY_ID } from '../constants/constants'

import { toast } from 'react-toastify'
import API from 'src/redux/API'

const initialState = {
  news: {},
  oneNews: {},
  filter: {
    page: 1,
    value: '',
  },
}

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_NEWS:
      return {
        ...state,
        news: action.payload,
      }
    case GET_NEWS_BY_ID:
      return {
        ...state,
        oneNews: action.payload,
      }
    case FILTER_DATA_NEWS:
      return {
        ...state,
        filter: { ...action.payload },
      }
    default:
      return state
  }
}

export const getListNews = (page = 1, value) => {
  return async (dispatch) => {
    await API.getListNews(page, value).then(({ data }) => {
      return dispatch({ type: GET_NEWS, payload: data })
    })
  }
}

export const deleteNews = (id, page) => {
  return async (dispatch) => {
    await API.deleteNews(id)
      .then((res) => {
        toast.success('Новость удалена')
        dispatch(getListNews(page))
      })
      .catch((err) => toast.error('Что то пошло не так :('))
  }
}

export const createNews = (title, content, image0Url, image1Url, callback) => {
  return async () => {
    await API.createNews({ title, content, image0Url, image1Url })
      .then((res) => {
        setTimeout(() => toast.success('Новость успешно создана!!'), 50)
        callback()
      })
      .catch((err) => {
        console.log(err)
        toast.error('Что то пошло не так :(')
      })
  }
}

export const getNewsById = (id) => {
  return async (dispatch) => {
    await API.getNewsById(id)
      .then(({ data }) => dispatch({ type: GET_NEWS_BY_ID, payload: data }))
      .catch(() => console.log('error on try get one id'))
  }
}

export const updateNews = (id, { title, content, zeroImage, firstImage }, callback) => {
  return async () => {
    await API.updateNews(id, { title, content, image0Url: zeroImage, image1Url: firstImage })
      .then((res) => {
        setTimeout(() => toast.success('Новость успешно обновлена!!'), 50)
        callback()
      })
      .catch((err) => toast.error('Что то пошло не так :('))
  }
}

export const saveFilterNews = (data) => {
  return (dispatch) => {
    dispatch({ type: FILTER_DATA_NEWS, payload: data })
  }
}
