import {
  GET_DATA_USER_BY_ID,
  GET_PASSPORT_DATA_FOR_IDENTIFICATION,
  IS_CHECKED_IDENDIFICATE,
  LOADING_PASSWORD_DATA,
  LOADING_PASSWORD_DATA_FINISHED,
} from '../constants/constants'

const initialState = {
  data: {},
  passportData: {},
  imagePasswordData: '',
  image: [],
  isLoading: false,
  isConfirm: false,
}

const getDataUserById = (state = initialState, action) => {
  switch (action.type) {
    case GET_DATA_USER_BY_ID:
      return {
        ...state,
        data: { ...action.payload },
      }

    case GET_PASSPORT_DATA_FOR_IDENTIFICATION:
      return {
        ...state,
        passportData: { ...action.payload },
      }
    case 'RES_IMAGE':
      return {
        ...state,
        image: [...state.image, action.payload],
      }
    case 'CLEAR_DETAIL_IMAGES':
      return {
        ...state,
        imagePasswordData: '',
        image: [],
      }
    case 'IMAGE_PASSWORD_DATA':
      return {
        ...state,
        imagePasswordData: action.payload,
      }
    case 'CLEAR_STORE':
      return {
        data: {},
        passportData: {},
        image: [],
        isLoading: false,
      }
    case LOADING_PASSWORD_DATA:
      return {
        ...state,
        isLoading: true,
      }
    case IS_CHECKED_IDENDIFICATE:
      return {
        ...state,
        isConfirm: action.payload == 200 ? true : false,
      }
    case LOADING_PASSWORD_DATA_FINISHED:
      return {
        ...state,
        isLoading: false,
      }

    default:
      return state
  }
}
export default getDataUserById
