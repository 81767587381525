import { GET_ALL_COMMENTS } from '../constants/constants'

const initialState = []

export const AllCommentById = (state = initialState, action) =>{

    switch(action.type){
        case GET_ALL_COMMENTS:
            return action.payload
        
        default:
            return state
    }
}