import {
  CLEAR_TAGS_AND_CATEGORY,
  FILTER_DATA_CATEGORY,
  GET_ALL_CATEGORY,
  GET_CATEGORY_BY_ID,
} from '../constants/constants'

const initialState = {
  category: {},
  dataForUbdate: {},
  categoryName: null,
  filter: {
    page: 1,
    name: '',
  },
}

export const GetAllCategory = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_CATEGORY:
      return {
        ...state,
        category: { ...action.payload },
      }
    case GET_CATEGORY_BY_ID:
      return {
        ...state,
        dataForUbdate: action.payload,
      }
    case 'GET_CATEGORY_NAME_BY_ID':
      return {
        ...state,
        categoryName: action.payload,
      }
    case 'CLEAR_CATEGORY_NAME':
      return {
        ...state,
        categoryName: null,
      }
    case CLEAR_TAGS_AND_CATEGORY:
      return {
        ...state,
        dataForUbdate: {},
      }
    case FILTER_DATA_CATEGORY:
      return {
        ...state,
        filter: { ...action.payload },
      }
    default:
      return state
  }
}
