import {
  FILTER_DATA_PROMOTION,
  GET_LIST_PROMOTIONS,
  GET_MORE_INFO_PROMOTION,
} from '../constants/constants'

const initialState = {
  list: {},
  info: {},
  filter: {
    page: 1,
  },
}

export const getListPromotion = (state = initialState, action) => {
  switch (action.type) {
    case GET_LIST_PROMOTIONS:
      return {
        ...state,
        list: action.payload,
      }
    case GET_MORE_INFO_PROMOTION:
      return {
        ...state,
        info: action.payload,
      }
    case FILTER_DATA_PROMOTION:
      return {
        ...state,
        filter: { ...action.payload },
      }
    default:
      return state
  }
}
