import {
  GET_DATA_PARTNER_BY_ID,
  GET_PASSPORT_DATA_FOR_IDENTIFICATION,
  IS_CHECKED_IDENDIFICATE,
  LOADING_PASSWORD_DATA,
  LOADING_PASSWORD_DATA_FINISHED,
} from '../constants/constants'

const initialState = {
  data: {},
  passportData: {},
  partnerLogo: '',
  image: [],
  passportImage: [],
  isLoading: false,
  isConfirm: true,
}

const getDataPartnerById = (state = initialState, action) => {
  switch (action.type) {
    case GET_DATA_PARTNER_BY_ID:
      return {
        ...state,
        data: { ...action.payload },
      }

    case GET_PASSPORT_DATA_FOR_IDENTIFICATION:
      return {
        ...state,
        passportData: { ...action.payload },
      }
    case 'RES_IMAGE_PARTNER':
      return {
        ...state,
        image: [...state.image, action.payload],
      }
    case 'RES_PASSPORT_IMAGE_PARTNER':
      return {
        ...state,
        passportImage: [...state.passportImage, action.payload],
      }
    case 'PARTNER_LOGO':
      return {
        ...state,
        partnerLogo: action.payload,
      }
    case 'CLEAR_STORE_PARTNER':
      return {
        data: {},
        passportData: {},
        image: [],
        passportImage: [],
        isLoading: false,
      }
    case LOADING_PASSWORD_DATA:
      return {
        ...state,
        isLoading: true,
      }
    case IS_CHECKED_IDENDIFICATE:
      return {
        ...state,
        isConfirm: action.payload === 200 ? true : false,
      }
    case LOADING_PASSWORD_DATA_FINISHED:
      return {
        ...state,
        isLoading: false,
      }
    case 'CLEAR_DETAIL_INFO':
      return {
        ...state,
        partnerLogo: '',
        image: [],
        passportImage: [],
      }
    default:
      return state
  }
}
export default getDataPartnerById
