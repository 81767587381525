const initialState = {
  list: null,
  info: null,
  user: null,
  videos: null,
  files: [],
  filter: {
    name: '',
    surname: '',
    patronymic: '',
    status: '',
    phone: '',
    page: 1,
  },
}

export const GetUsers = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_USERS':
      return {
        ...state,
        list: action.payload,
      }
    case 'GET_USER_DETAIL_BY_ID':
      return {
        ...state,
        info: action.payload,
      }
    case 'GET_USER_BY_PHONE_NUMBER':
      return {
        ...state,
        user: action.payload,
      }
    case 'GET_USER_FILES':
      return {
        ...state,
        files: action.payload,
      }
    case 'GET_USER_VIDEOS':
      return {
        ...state,
        videos: action.payload,
      }
    case 'SAVE_PAG_AND_FILTER_USERS':
      return {
        ...state,
        filter: action.payload,
      }
    default:
      return state
  }
}
